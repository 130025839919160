<template>
  <v-app>
    <div class="cover" :style="cssProps">
      <div class="opacity"></div>
      <div class="text">
        <img src="../assets/img/logos.png" width=200 alt="">
        <h1>DIECI SUITE <br> a TEMA</h1>
        <h5 style="background: #E53935;
border-radius: 5px;
padding: 5px;
box-shadow: 4px 5px 7px #E53935A1;
margin-top: 20px;">
          per rendere più piacevole il vostro soggiorno <br> 
          sulla collina di Torino
        </h5>
      </div>
    </div>
    <div class="stanze ">
      <div class="container">
        <h2 style="text-align: center">Perche sceglierci</h2>
        <div class="card-stanze">
          <div class="stanze-flex">
            <h6>
              Siamo specialisti in Intimity Time nel panorama dell’accoglienza
              alberghiera dedicata al Piacere Adulto.
            </h6>
          </div>
          <div class="btn-flex">
            <button
              id="prenota"
              onclick="window.open('https://wa.me/393668766245?text=vorrei%20avere%20informazioni%20sulle%20stanze%20')"
            >
              Prenota una camera
            </button>
            <button id="contatti" onclick="location.href = 'tel: +393668766245'">
              Contattaci
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="servizi">
      <div class="container">
        <h2 style="text-align: center">Servizi</h2>
      <div class="card-stanze mt-5">
        <div class="">
          <h5>Tutte le nostra stanze hanno: </h5>
          <div class="spec" style="text-align: left;  max-width:500px; margin: 0 auto; margin-top:20px;">
            <p><v-icon style="font-size:50px" dark>mdi-wifi</v-icon> La connessione a internet wifi </p>
            <p><v-icon style="font-size:50px" dark>mdi-home-assistant</v-icon> Controlli domitici </p>
            <p><v-icon style="font-size:50px" dark>mdi-room-service</v-icon> Servizio in camera con il massimo rispetto per la vostra privacy </p>
            <p><v-icon style="font-size:50px" dark>mdi-parking</v-icon> Il parcheggio è riservato ed è contrassegrato dal logo della camera che avete prenotato </p>
          </div>
           <button
              id="stanze"
              to="/stanze"
            >
              Scopri le nostre stanze
            </button>

        </div>
      </div>
      </div>
    </div>
    <div class="">
      <div class="container">
        <h2 style="text-align: center">Tariffe</h2>
      <div class="card-stanze mt-5">
          <v-data-table style="cursor: pointer"
          
            :headers="headers"
            :items="desserts"
            @click:row="clicked"
            class="elevation-1"
          ></v-data-table>
      </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      cssProps: {
        backgroundImage: `url(${require("@/assets/img/img/torino.jpg")})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundSize: "cover",
      },

      headers: [
          {
            text: 'Stanza',
            align: 'start',
            sortable: false,
            value: 'name',
            
          },
          { text: '4h', value: 'h' },
          { text: '6h', value: 'mh' },
          { text: 'Notte', value: 'notte' },
        ],
        desserts: [
          {
            name: 'Jaipur',
            h: 79,
            mh: 99,
            notte: 149,
            i: 0,
          },
          {
            name: 'Sioux',
            h: 79,
            mh: 99,
            notte: 149,
            i: 1,
          },
          {
            name: 'Iquito',
            h: 79,
            mh: 99,
            notte: 149,
            i: 2,
          },
          {
            name: 'Pegasus',
            h: 79,
            mh: 99,
            notte: 149,
            i: 3,
          },
          {
            name: 'Aton Ra',
            h: 99,
            mh: 129,
            notte: 199,
            i: 4,
          },
          {
            name: 'Grotta Bianca',
            h: 99,
            mh: 129,
            notte: 199,
            i: 5,
          },
          {
            name: 'Miniera di Carbonia',
            h: 99,
            mh: 129,
            notte: 199,
            i: 6,
          },
          {
            name: 'Cnosso',
            h: 119,
            mh: 159,
            notte: 239,
            i: 7,
          },
          {
            name: 'Pietro Micca',
            h: 119,
            mh: 159,
            notte: 239,
            i: 8,
          },
          {
            name: 'Cantine Segrete',
            h: 119,
            mh: 159,
            notte: 239,
            i: 9,
          },

        ],
    };
  },
  components: {},
  methods: {
    clicked(e){
      console.log(e.i);
      this.$router.push({ name: 'Stanze', params: {tabs: e.i}});
    }
  }
};
</script>
