<template>
  <v-card color="basil">
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="basil"
      grow
      center-active
    >
      <v-tab
        v-for="item in items"
        :key="item"
      >
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(txt, i) in obj"
        :key="i"
        
      >
        <v-card

        >
          <div style="position:relative" class="servizi">
            <v-carousel>
              <v-carousel-item
                v-for="(item,i) in txt.img"
                :key="i"
                :src="item.src"
                reverse-transition="fade-transition"
                transition="fade-transition"
              ></v-carousel-item>
            </v-carousel>
            <img :src="txt.logo" alt="" width="80" style="position:absolute;top: 20px;left: 20px;">
          </div>
          <div class="servizi">
            <v-container class="">
            <h3 v-html="txt.title"></h3>
            <p class="mt-5" v-html=" txt.text"></p>
          </v-container>
          </div>

          <div class="">
            <v-container>
              <h4><b>Dettagli Destinazione:</b></h4>
              <div class="main-grid">
                <div class="grid-div" v-for="(item, i) in txt.feature" :key="i">
                  <img :src="item.src" alt=""> <p v-html="item.text"></p>
                
                </div>
              </div>

              <div class="mt-10">
                <h5><b>La tariffa comprende:</b></h5><br>
               <div class="main-grid2">
                  <div class="grid-div" v-for="(item, i) in txt.tariffe" :key="i">
                  <img :src="item.src" alt=""> <p style="padding:0px 20px" v-html="item.text"></p>
                </div>
               </div>
              </div>
            </v-container>
          </div>

          <div class="servizi">
            <v-container >
            <h4  v-html="txt.title2 "></h4>
            <p class="mt-5" v-html="txt.text2 "></p>
          </v-container>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
  export default {
    props: {
      tabs: Number,
    },
    data () {
      return {
        tab: 0,
        items: [
          'Jaipur', 'Sioux', 'Iquito', 'Pegasus','Aton Ra','Grotta Bianca','Miniera di Carbonia','Cnosso','Pietro Micca','Cantine Segrete'
        ],
        obj: [
          {
            logo: require("../assets/img/img/Jaipur.png"),
            title:'Vestiti a festa… stai entrando a Jaipur “la Città Rosa”',
            text: `Con il suo letto orientale, il baldacchino fiorito e i colori delle sue mille finestre dalle quali scrutarsi a vicenda per un piacevole gioco…
                   Non trovi i suoi pittoreschi e colorati bazar ma puoi vivere un turbinio di emozioni e sensazioni anche nella riservatezza della tua stanza.
                  <br><br>Concediti ora un piacevole bagno dall’evocativo sapore orientale… gli specchi e la calda illuminazione renderanno sfarzosa la tua permanenza in questo luogo`,
            title2: 'Emotica',
            text2: `Un viaggio di sogno e di passione, nel passato e nel presente… Scegli dal menu del touch screen la musica che ti fa sentire un Maharaja e parti da un bagno rigenerante nella vasca a tema che inizia ad avvolgerti in un rosa Reale…
                    <br>Surreale e magico lo diventa nell’avvolgere le vostre danze multicolori della doccia cromoterapica che vi sovrasta… Sei forse nella stanza privata delle udienze amorose…? Che la tua donna come per incanto assuma le vesti di tutte le femmine del reame..?
                    <br><br>Questo è il mio pensiero e non solo magico ma propiziatorio come i veri portalumi, ora rovesciati che vi accolgono…illuminando il calore del letto… Sono autentici ed hanno una “storia reale” come la provenienza…: un mio viaggio a Jaipur – Lady Six …`,
            
            tariffe: [
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Minibar con acqua naturale/frizzante 0,5 L",
              },
              {
                src: require("../assets/img/img/loghi_stanze_servizio.png"),
                text: "Kit con spazzolino da denti, dentifricio, set viso/mani, ciabatte e cuffietta.",
              },
            ],
            feature:[
              {
                src: require("../assets/img/img/loghi_stanze_parcheggio.png"),
                text: " Parcheggio riservato con logo Jaipur (seguire le indicazioni fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Area esterna riservata",
              },
              {
                src: require("../assets/img/img/loghi_stanze_telefono.png"),
                text: "Numero diretto per extra e assistenza.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_tv.png"),
                text: "TV LCD HD orientabile.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_monitor.png"),
                text: "Monitor Touch Screen per il controllo scenari e musiche in Emotica.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_clima.png"),
                text: "Climatizzatore caldo/freddo ad integrazione del comfort personale con controllo a telecomando locale.",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Asciuga capelli.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_idro.png"),
                text: "Minipiscina interna con idromassaggio localizzato per un massimo di 4 persone (informazioni d’uso fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze_doccia.png"),
                text: "Doccia cromatica.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_vano.png"),
                text: "Vano passavivande per le vostre richieste extra e l’assoluta privacy.",
              }
            ],
            img: [
              {
                src: require("../assets/img/Suite-a-tema-jaipur-panoramica.jpg"),
              },
              
              {
                src: require("../assets/img/img/jaipur1.jpg"),
              },
              {
                src: require("../assets/img/img/jaipur2.jpg"),
              },
              {
                src: require("../assets/img/img/jaipur3.jpg"),
              }
              
            ]
          },
          {

            logo: require("../assets/img/img/Sioux.png"),
            title:'Nella sacralità del più solenne dei rituali indiani”',
            text: `“La danza del sole” vai alla ricerca di una visione…
                  Sei in Sioux .. ti stai trasformando in Toro Seduto o Cavallo Pazzo. Forse come loro osserverai il digiuno ma non dalle emozioni… Una presenza si fa sentire…
                  <br><br>
                  Sarà il tuo spirito antenato o semplicemente il tuo partner? Ti guardi intorno e trovi la tua tenda tepee pronta ad accogliere le tue membra desiderose di riposo dopo l’estenuante caccia..
                  Non c’è Nuvola Rossa a minacciarti con la sua guerra ma una pozza dello stesso colore per un travolgente bagno a due.`,
            title2: 'Emotica',
            text2: `Chi non ha giocato agli indiani da bambino? Siamo certi che qualcuno di voi esclamerà Augh dopo il magico ingresso in questa Destinazione!
                  
                  Abbiamo pensato di farvi vivere l’emozione di una vera tenda indiana unita al comfort di un ergonomico materasso contemporaneo …
                  <br><br>Non possiamo tingervi la pelle di rosso ma lo abbiamo fatto con i decori della stanza… La maxivasca idromassaggio integrata nella terra rossa come in una dimora originaria… le vere pelli di bisonte … i colori e il paesaggio perfettamente integrati … vi fanno sentire al riparo dalle insidie dei pionieri…

                  Fatevi suggerire dai colori e profumi degli scenari emotici ideati per voi a quale gioco giocare …tutto vi è concesso tranne accendere un fuoco!`,
            
             tariffe: [
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Minibar con acqua naturale/frizzante 0,5 L",
              },
              {
                src: require("../assets/img/img/loghi_stanze_servizio.png"),
                text: "Kit con spazzolino da denti, dentifricio, set viso/mani, ciabatte e cuffietta.",
              },
            ],
            feature:[
              {
                src: require("../assets/img/img/loghi_stanze_parcheggio.png"),
                text: "Parcheggio riservato con logo Sioux (seguire le indicazioni fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze_telefono.png"),
                text: "Numero diretto per extra e assistenza.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_tv.png"),
                text: "TV LCD HD orientabile.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_monitor.png"),
                text: "Monitor Touch Screen per il controllo scenari e musiche in Emotica.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_clima.png"),
                text: "Climatizzatore caldo/freddo ad integrazione del comfort personale con controllo a telecomando locale.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_idro.png"),
                text: "Minipiscina interna con idromassaggio localizzato per un massimo di 4 persone (informazioni d’uso fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze_doccia.png"),
                text: "Vasca con idromassaggio per un massimo di 2 persone (informazioni d’uso fornite dal personale al momento del Check In).",
              },
              
              {
                src: require("../assets/img/img/loghi_stanze_vano.png"),
                text: "Vano passavivande per le vostre richieste extra e l’assoluta privacy.",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Asciuga capelli.",
              }
            ],
            img: [
              {
                src: require("../assets/img/Suite-a-tema-Sioux-panoramica.jpg"),
              },
              
              {
                src: require("../assets/img/img/sioux2.jpg"),
              },
              {
                src: require("../assets/img/img/sioux3.jpg"),
              },
              {
                src: require("../assets/img/img/sioux4.jpg"),
              }
              
            ]
          },
          {
            logo: require("../assets/img/img/Iquito.png"),
            title:'Eccoti nella foresta amazzonica… ',
            text: `Stai esplorando il variopinto mondo magico che ti circonda e ti senti accolto dai movimenti della natura… Contempli il paesaggio di un luogo unico e la tua fantasia ti fa immaginare la navigazione sul Rio delle Amazzoni…
                  Sono le onde di un piacevole bagno quelle che sollazzano te e il tuo partner. Una nuova scoperta: il letto sul quale adagiarsi è appeso al soffitto della capanna e cullerà i vostri sogni e non solo…
                  <br><br>La vegetazione sembra coprire la voce della foresta ma non le sue musiche.`,
            title2: 'Emotica',
            text2: `Stai prenotando una Destinazione nella foresta amazzonica… Al tuo ingresso accedi ad un luogo dove tutto è stato scelto accuratamente per farti sentire lontano da casa ma selvaggiamente vicino al tuo partner …
                    Si innalzano nebbie e fumi, scoppiano temporali e fuggono animali alla ricerca di rifugio.
                    <br><br>Sono alcuni degli scenari emotici che abbiamo ideato per voi. Il letto in legno culla i vostri sogni e dà movimento ai vostri corpi, prima di farvi coccolare da un lungo bagno a colori nella maxivasca… `,
            
              tariffe: [
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Minibar con acqua naturale/frizzante 0,5 L",
              },
              {
                src: require("../assets/img/img/loghi_stanze_servizio.png"),
                text: "Kit con spazzolino da denti, dentifricio, set viso/mani, ciabatte e cuffietta.",
              },
            ],
            feature:[
              {
                src: require("../assets/img/img/loghi_stanze_parcheggio.png"),
                text: "Parcheggio riservato con logo Iquito (seguire le indicazioni fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Area esterna riservata",
              },
              {
                src: require("../assets/img/img/loghi_stanze_tv.png"),
                text: "TV LCD HD orientabile.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_telefono.png"),
                text: "Numero diretto per extra e assistenza.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_monitor.png"),
                text: "Monitor Touch Screen per il controllo scenari e musiche in Emotica.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_clima.png"),
                text: "Climatizzatore caldo/freddo ad integrazione del comfort personale con controllo a telecomando locale.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_idro.png"),
                text: "Vasca con idromassaggio per un massimo di 2 persone (informazioni d’uso fornite dal personale al momento del Check In)",
              },
              {
                src: require("../assets/img/img/loghi_stanze_doccia.png"),
                text: "Doccia cromatica",
              },
              
              {
                src: require("../assets/img/img/loghi_stanze_vano.png"),
                text: "Vano passavivande per le vostre richieste extra e l’assoluta privacy.",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Asciuga capelli.",
              }
            ],
            img: [
              {
                src: require("../assets/img/Suite-a-tema-iquito-panoramica.jpg"),
              },
              
              {
                src: require("../assets/img/img/iquito2.jpg"),
              },
              {
                src: require("../assets/img/img/iquito3.jpg"),
              },
              {
                src: require("../assets/img/img/iquito4.jpg"),
              }
              
            ]
          
          },
          {
            logo: require("../assets/img/img/Pegasus.png"),
            title:'Per sentirsi un cavaliere dello zodiaco',
            text: `O un eterno sognatore a caccia di stelle benaugurali ti aspetta la tua costellazione personale che ti avvolge nell’immenso dell’universo.
                  La zona letto è rialzata per darti profondità e comfort e sentirti più vicino alla tua stella.
                  <br><br>Pegaso volò in cielo… Se tu e il tuo partner vi lasciate andare alle emozioni lo toccherete con un dito… Lasciate lambire i vostri corpi dai flutti d’acqua e i vostri sensi apprezzeranno il destino che avete scelto per loro.`,
            title2: 'Emotica',
            text2: `Hai prenotato un viaggio stellare… cominci a contare le stelle per farti abbracciare da Morfeo o preferisci far lambire il tuo corpo e quello del partner dalle calde acque dell’idromassaggio?
                  A te la scelta! Come quella della colonna musicale e non solo da dare alla tua notte stellata…
                  <br><br>Tutto è concentrato in una stanza… ma da sogno ad occhi aperti! Dal benvenuto, passando al relax per arrivare alla buonanotte abbiamo programmato degli scenari che contribuiranno a farti toccare il cielo con un dito.
                  Magie del touch screen e dell’alchimia dei vostri sensi…`,
            
              tariffe: [
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Minibar con acqua naturale/frizzante 0,5 L",
              },
              {
                src: require("../assets/img/img/loghi_stanze_servizio.png"),
                text: "Kit con spazzolino da denti, dentifricio, set viso/mani, ciabatte e cuffietta.",
              },
            ],
            feature:[
              {
                src: require("../assets/img/img/loghi_stanze_parcheggio.png"),
                text: "Parcheggio riservato con logo Pegasus (seguire le indicazioni fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze_telefono.png"),
                text: "Numero diretto per extra e assistenza.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_tv.png"),
                text: "TV LCD HD orientabile.",
              },
              
              {
                src: require("../assets/img/img/loghi_stanze_monitor.png"),
                text: "Monitor Touch Screen per il controllo scenari e musiche in Emotica.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_clima.png"),
                text: "Climatizzatore caldo/freddo ad integrazione del comfort personale con controllo a telecomando locale.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_idro.png"),
                text: "Vasca con idromassaggio per un massimo di 2 persone (informazioni d’uso fornite dal personale al momento del Check In)",
              },
              {
                src: require("../assets/img/img/loghi_stanze_doccia.png"),
                text: "Doccia cromatica",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Area esterna riservata",
              },

              {
                src: require("../assets/img/img/loghi_stanze_vano.png"),
                text: "Vano passavivande per le vostre richieste extra e l’assoluta privacy.",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Asciuga capelli.",
              }
            ],
            img: [
              {
                src: require("../assets/img/img/Pegasus.png"),
              },
              {
                src: require("../assets/img/img/pegasus2.jpg"),
              },
              {
                src: require("../assets/img/img/pegasus3.jpg"),
              },
              {
                src: require("../assets/img/img/pegasus4.jpg"),
              },
              
            ]
          },
          {
            logo: require("../assets/img/img/Aton_Ra.png"),
            title:'Creatore per eccellenza, emerso dalla massa liquida primordiale Nun.',
            text: `Ora sei catapultato nelle geometriche forme della dimora del Faraone di un’antica civiltà.
                  All’esterno della piramide il sole calante porta gli astri sopra il tuo corpo senza mai tramontare nella notte.
                  <br><br>Non saranno le entità ibride del Pantheon Egizio a indicarti i tre livelli dove emergono maestosi scrigni e stupefacenti sepolcri.`,
            title2: 'Emotica',
            text2: `Pretestuoso farvi sentire faraonici ma dopo i primi attimi di buio al vostro ingresso, le stelle appaiono nel vostro cielo per cogliere il fascino unico dell’Aton Ra.
                    Siete all’ingresso della Piramide, non vogliamo mummificarvi ma farvi giocare in un luogo criptico e misterioso… Immergetevi nella simpatica mini-piscina rettangolare scavata nei massi egizi e godetevi il massaggio dell’acqua.

                    <br><br>Poi che dire di un aperitivo o uno spuntino a bordo dell’esclusiva maxivasca matrimoniale? Accompagnano e stuzzicano i vostri sensi gli effetti speciali da scegliere sul tecnologico touch screen con un semplice tocco… Non vi rimane che prenotare il vostro viaggio… `,
             tariffe: [
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Minibar con acqua naturale/frizzante 0,5 L",
              },
              {
                src: require("../assets/img/img/loghi_stanze_servizio.png"),
                text: "Kit con spazzolino da denti, dentifricio, set viso/mani, ciabatte e cuffietta.",
              },
            ],
            feature:[
              {
                src: require("../assets/img/img/loghi_stanze_parcheggio.png"),
                text: "Parcheggio riservato con logo Aton Ra e con ingresso diretto all’area esterna privata (seguire le indicazioni fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Area esterna riservata",
              },
              {
                src: require("../assets/img/img/loghi_stanze_telefono.png"),
                text: "Numero diretto per extra e assistenza.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_tv.png"),
                text: "TV LCD HD orientabile.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_parcheggio.png"),
                text: "Climatizzatore caldo/freddo ad integrazione del comfort personale con controllo a telecomando locale.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_clima.png"),
                text: "Monitor Touch Screen per il controllo scenari e musiche in Emotica.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_idro.png"),
                text: "Minipiscina interna con idromassaggio localizzato per un massimo di 4 persone (informazioni d’uso fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze_doccia.png"),
                text: "Doccia cromatica.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_vano.png"),
                text: "Vano passavivande per le vostre richieste extra e l’assoluta privacy.",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Asciuga capelli.",
              }
            ],
            img: [
              {
                src: require("../assets/img/Suite-a-tema-aton-ra-panoramica.jpg"),
              },
              {
                src: require("../assets/img/img/atonra1.jpg"),
              },
              {
                src: require("../assets/img/img/atonra2.jpg"),
              },
              {
                src: require("../assets/img/img/atonra3.jpg"),
              },
              
            ]
          },
          {

            logo: require("../assets/img/img/Grotta-bianca-l.png"),
            title:'Speleologo? Un caschetto con la luce?',
            text: `Stai cercando la purezza del tuo partner: sei nel luogo giusto, è magico e incantato. Non badare ai tuoi passi, stai scivolando sulle ali di una fantasia ora reale… la caverna è millenaria e ormai senza tempo.
                  Ti avvolge il bianco incontrastato della roccia che si specchia nella pozza d’acqua che via via prende forma, le acque ora corrono veloci come i tuoi pensieri per portarti al miglior piacere.

                  Hai un dubbio…! L’atmosfera si colora e tradisce se stessa… Non è più chiaro se sei rimasto l’esploratore che immaginavi ma è arrivato il momento di osare e usare…
                  <br><br>Se hai il tempo di alzare lo sguardo le stalattiti ti accompagnano nel segno dei tempi, ma non stai sognando, è l’acqua che scende dall’immensità che ti sovrasta.`,
            title2: 'Emotica',
            text2: `Una destinazione bianca e glaciale… rimanda a paesaggi carsici e millenari. Suggestioni luccicanti si scatenano con l’aiuto degli scenari emotici…
                  <br>
                  Ti diamo il benvenuto nella Grotta Bianca: fatti trascinare dalle emozioni guidate dai nostri effetti speciali in un percorso a ritroso nel tempo..
                  Questo è il nostro benvenuto! Aspetterei ancora ad arrivare alla buonanotte, immergiti prima in acque limpide e pure inquinate solo dai tuoi pensieri.

                  <br><br>Trovi delle vere bolle, create da un idromassaggio autoprodotto anche per fatti stimolare dai giochi cromatici in contrasto con il bianco assoluto.
                  Fatti accompagnare…sotto una doccia rinvigorente o in un calmo o frenetico riposo nel letto sui tronchi secolari e pelli. Forse potresti voler provare la vicinanza più assoluta con il tuo partner e sperimentare un simpatico lettino su assi di legno… Tutto può essere liberamente e atavicamente interpretato.`,

              tariffe: [
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Minibar con acqua naturale/frizzante 0,5 L",
              },
              {
                src: require("../assets/img/img/loghi_stanze_servizio.png"),
                text: "Kit con spazzolino da denti, dentifricio, set viso/mani, ciabatte e cuffietta.",
              },
            ],
            feature:[
              {
                src: require("../assets/img/img/loghi_stanze_parcheggio.png"),
                text: "Parcheggio riservato con logo Grotta Bianca (seguire le indicazioni fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Area esterna riservata",
              },
              {
                src: require("../assets/img/img/loghi_stanze_telefono.png"),
                text: "Numero diretto per extra e assistenza.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_tv.png"),
                text: "TV LCD HD orientabile.",
              },
              
              {
                src: require("../assets/img/img/loghi_stanze_monitor.png"),
                text: "Monitor Touch Screen per il controllo scenari e musiche in Emotica.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_clima.png"),
                text: "Climatizzatore caldo/freddo ad integrazione del comfort personale con controllo a telecomando locale.",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Asciuga capelli.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_idro.png"),
                text: "Minipiscina interna con idromassaggio localizzato per un massimo di 4 persone (informazioni d’uso fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze_doccia.png"),
                text: "Doccia  e cascata cromatica",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Lettino per massaggi in legno",
              },
              

              {
                src: require("../assets/img/img/loghi_stanze_vano.png"),
                text: "Vano passavivande per le vostre richieste extra e l’assoluta privacy.",
              }
            ],
            img: [
              {
                src: require("../assets/img/img/GrottaBianca.jpg"),
              },
              
              {
                src: require("../assets/img/img/grottabianca2.jpg"),
              },
              
              {
                src: require("../assets/img/img/grottabianca3.jpg"),
              },
              
            ]
          },
          {
            logo: require("../assets/img/img/Miniera_Carbonia.png"),
            title:'E il suo affaccio in superficie.',
            text: `Un vera miniera di carbone con carrelli in ferro massiccio e letto in movimento per raggiungere la frontiera della tua coscienza.
                  <br><br>I tuoi pensieri più neri e profondi si agitano nell’immaginazione. Ora hai varcato l’ingresso… e quasi per accecarti hai trovato il luogo magico per le tue notti in bianco coniugate alle bocche del desiderio.`,
            title2: 'Emotica',
            text2: `Miniera di Carbonia…una Destinazione dal tema delicato, luoghi dove non entra mai il sole e la luce non può che arrivare da piccole lampade.
                  Se prenotate questa Destinazione non avrete la medesima sorte e magari sfortuna di chi ha svolto un mestiere durissimo e ormai antico…: i nostri rispettabilissimi minatori.

                  <br><br>Per voi sarà un gioco magico in cui sospendere il battito del tempo esterno, in cui i tesori da scoprire saranno immediatamente alla vostra portata e senza fatica…
                  L’acqua non smetterà di sgocciolare solo se voi lo vorrete…per un tuffo di benessere nella minipiscina idromassaggio. Pensieri anche impuri, troveranno piena accoglienza nel letto matrimoniale su ruote a rappresentare un carrello…colmo di desiderio…`,

              tariffe: [
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Minibar con acqua naturale/frizzante 0,5 L",
              },
              {
                src: require("../assets/img/img/loghi_stanze_servizio.png"),
                text: "Kit con spazzolino da denti, dentifricio, set viso/mani, ciabatte e cuffietta.",
              },
            ],
            feature:[
              {
                src: require("../assets/img/img/loghi_stanze_parcheggio.png"),
                text: "Parcheggio riservato con logo Miniera di Carbonia (seguire le indicazioni fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Area esterna riservata",
              },
              {
                src: require("../assets/img/img/loghi_stanze_telefono.png"),
                text: "Numero diretto per extra e assistenza.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_tv.png"),
                text: "TV LCD HD orientabile.",
              },
              
              {
                src: require("../assets/img/img/loghi_stanze_monitor.png"),
                text: "Monitor Touch Screen per il controllo scenari e musiche in Emotica.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_clima.png"),
                text: "Climatizzatore caldo/freddo ad integrazione del comfort personale con controllo a telecomando locale.",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Asciuga capelli.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_idro.png"),
                text: "Minipiscina interna con idromassaggio localizzato per un massimo di 4 persone (informazioni d’uso fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze_doccia.png"),
                text: "Doccia cromatica",
              },
              

              {
                src: require("../assets/img/img/loghi_stanze_vano.png"),
                text: "Vano passavivande per le vostre richieste extra e l’assoluta privacy.",
              }
            ],
            img: [
              {
                src: require("../assets/img/Suite-a-tema-miniera-di-carbone-panoramica.jpg"),
              },
              
              {
                src: require("../assets/img/img/miniera2.jpg"),
              },
              {
                src: require("../assets/img/img/miniera3.jpg"),
              },
              {
                src: require("../assets/img/img/miniera4.jpg"),
              },
              
            ]
          },
          {
            logo: require("../assets/img/img/Cnosso.png"),
            title:'Prigionieri per una notte o qualche ora a Cnosso',
            text: `La tua curiosità spinge la mente a farsi avanti attraverso sentieri tortuosi fatti di immagini non sempre reali.
                  E’ il tuo Labirinto dove il mistero attira il girovagare. Una Destinazione dove perdersi e ritrovarsi sarà un gioco senza fine.
                  <br><br>Icaro vi ha lasciato in eredità le sue ali… non per volare via ma per trasportarvi in un mondo fantastico e misterioso.`,
            title2: 'Emotica',
            text2: `Stai prenotando una Destinazione misteriosa, Cnosso ti aspetta per piacevolmente ingannare un’intera notte o poche ore.
                    All’ingresso nel nostro fantasioso labirinto si alterneranno rumori, musiche, luci e profumi: effetti speciali selezionabili sul monitor touch screen posto all’ingresso.

                    <br><br>Nel piccolo intrico scatenato nella vostra mente sarà facile perdersi tra un rilassante bagno idromassaggio, un salutare bagno di vapore o una rinvigorente doccia cromoterapica.

                    <br>Ricordate…siete qui per il benessere della vostra coppia…non serve una mappa per ritrovarsi ma il vostro desiderio di percorrere ed assaporare un alternativo Intimity Time.`,

                 tariffe: [
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Minibar con acqua naturale/frizzante 0,5 L",
              },
              {
                src: require("../assets/img/img/loghi_stanze_servizio.png"),
                text: "Kit con spazzolino da denti, dentifricio, set viso/mani, ciabatte e cuffietta.",
              },
            ],
            feature:[
              {
                src: require("../assets/img/img/loghi_stanze_parcheggio.png"),
                text: "Parcheggio riservato con logo Cnosso(seguire le indicazioni fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Area esterna riservata",
              },
              {
                src: require("../assets/img/img/loghi_stanze_telefono.png"),
                text: "Numero diretto per extra e assistenza.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_tv.png"),
                text: "TV LCD HD orientabile.",
              },
              
              {
                src: require("../assets/img/img/loghi_stanze_monitor.png"),
                text: "Monitor Touch Screen per il controllo scenari e musiche in Emotica.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_clima.png"),
                text: "Climatizzatore caldo/freddo ad integrazione del comfort personale con controllo a telecomando locale.",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Asciuga capelli.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_idro.png"),
                text: "Minipiscina interna con idromassaggio localizzato per un massimo di 4 persone (informazioni d’uso fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze_doccia.png"),
                text: "Doccia cromatica",
              },
              

              {
                src: require("../assets/img/img/loghi_stanze_vano.png"),
                text: "Vano passavivande per le vostre richieste extra e l’assoluta privacy.",
              }
            ],
            img: [
              {
                src: require("../assets/img/Suite-a-tema-cnosso-panoramica.jpg"),
              },
              
              {
                src: require("../assets/img/img/cnosso2.jpg"),
              },
              {
                src: require("../assets/img/img/cnosso3.jpg"),
              },
              {
                src: require("../assets/img/img/cnosso4.jpg"),
              },
              
              
            ]
          },
          {
            logo: require("../assets/img/img/Pietro_Micca.png"),
            title:'Con le sue gallerie e i suoi saliscendi del 1700',
            text: `per tornare ai tempi dell’eroica Resistenza di Torino all’invasione francese… Non sentirti assediato: sei nella Fortezza delle tue coinvolgenti emozioni, lasciale esplodere come una miccia dentro di te…
                  <br><br>Ora difendi il tuo corpo e quello del partner da un attacco di benessere. I marchingegni che trovi non sono nati per la guerra ma per la cura dei tuoi sensi.`,
            title2: 'Emotica',
            text2: `Apri una porta! E’ l’ultima che ti separa dalla tua Destinazione. Ti accoglie la profondità del buio e per quanto aguzzi la vista ora devi affidarti alla fantasia.

                    Pochi attimi e puoi immaginare di trovarti in cunicoli scavati forse 300 anni fa. Sei nell’assediata Torino ma devi resistere! Fanno seguito effetti di ogni tipo: rumori molesti, pulviscolo, olezzi sconosciuti.

                    <br><br>Forse un’esplosione?! All’improvviso tutto si rischiara e scopri di dover e poter imprigionare.. per liberare tutte le tue bellicose intenzioni anche le più passionali; ti aspettano una grande vasca idromassaggio per piacevoli giochi d’acqua, un bagno di calore nella sauna perfettamente integrata e se non bastasse, nei giorni più caldi, una seconda vasca idromassaggio matrimoniale esterna completamente riservata nel tuo terrazzo privato.
                    Non puoi emulare l’eroica resistenza di allora!!! Arrenditi e Prenota !!!!`,
     tariffe: [
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Minibar con acqua naturale/frizzante 0,5 L",
              },
              {
                src: require("../assets/img/img/loghi_stanze_servizio.png"),
                text: "Kit con spazzolino da denti, dentifricio, set viso/mani, ciabatte e cuffietta.",
              },
            ],
            feature:[
              {
                src: require("../assets/img/img/loghi_stanze_parcheggio.png"),
                text: "Parcheggio riservato con logo Pietro Micca (seguire le indicazioni fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Area esterna riservata",
              },
              {
                src: require("../assets/img/img/loghi_stanze_telefono.png"),
                text: "Numero diretto per extra e assistenza.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_tv.png"),
                text: "TV LCD HD orientabile.",
              },
              
              {
                src: require("../assets/img/img/loghi_stanze_monitor.png"),
                text: "Monitor Touch Screen per il controllo scenari e musiche in Emotica.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_clima.png"),
                text: "Climatizzatore caldo/freddo ad integrazione del comfort personale con controllo a telecomando locale.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_sauna.png"),
                text: "Sauna per 3 persone (informazioni d’uso fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Asciuga capelli.",
              },
              
              {
                src: require("../assets/img/img/loghi_stanze_idro.png"),
                text: "Minipiscina interna con idromassaggio localizzato per un massimo di 4 persone (informazioni d’uso fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze_doccia.png"),
                text: "Doccia cromatica",
              },
              

              {
                src: require("../assets/img/img/loghi_stanze_vano.png"),
                text: "Vano passavivande per le vostre richieste extra e l’assoluta privacy.",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "area esterna riservata con mini-piscina e idromassaggio.",
              },
            ],
            img: [
              {
                src: require("../assets/img/Suite-a-tema-cantine-segrete-panoramica.jpg"),
              },
              
              {
                src: require("../assets/img/img/cantine_segrete2.jpg"),
              },
              {
                src: require("../assets/img/img/cantine_segrete3.jpg"),
              },
              {
                src: require("../assets/img/img/cantine_segrete4.jpg"),
              },
              
            ]
          },
          {
            logo: require("../assets/img/img/Cantine_Segrete.png"),
            title:'La pergola d’uva e i profumi della natura',
            text: `ti accompagnano all’ingresso della zona notte con le sue botti autentiche…
                  Puoi improvvisarti sommeiller di tutti i piaceri pregiati e nascosti: degustazioni esclusive e fuori dall’ordinario vi attendono…
                  <br><br>Le bollicine per il tuo relax ti invitano ad immergerti in un grande tino di rovere.`,
            title2: 'Emotica',
            text2: `Una destinazione dal profumo inebriante che solo certi vini sanno dare… Dormirai comodamente in un letto poggiato su botti autentiche e tufferai i tuoi pensieri bucolici in una grande tino creato appositamente per te.

                    Per completare il percorso benessere anche del tuo partner devi far assaggiare ai tuoi sensi la doccia cromoterapica e il bagno turco. E se la temperatura estiva si facesse troppo alta potrai optare per una rinfrescata nella vasca idromassaggio matrimoniale che in esterno è in esclusiva per voi.

                    <br><br>Abbiamo pensato a diversi scenari che vanno dal Benvenuto alla Buonanotte, scegli e ripeti dal touch screen lo scenario prediletto e verrai coinvolto da musiche, luci e effetti inaspettati. Annulla tutti i pensieri e fatti condurre insieme al tuo partner in una fiaba dal sapore campestre e idiallico, come tutte le fiabe sarà a lieto fine…`,

            tariffe: [
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Minibar con acqua naturale/frizzante 0,5 L",
              },
              {
                src: require("../assets/img/img/loghi_stanze_servizio.png"),
                text: "Kit con spazzolino da denti, dentifricio, set viso/mani, ciabatte e cuffietta.",
              },
            ],
            feature:[
              {
                src: require("../assets/img/img/loghi_stanze_parcheggio.png"),
                text: "Parcheggio riservato con logo Cantine Segrete (seguire le indicazioni fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Area esterna riservata",
              },
              {
                src: require("../assets/img/img/loghi_stanze_telefono.png"),
                text: "Numero diretto per extra e assistenza.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_tv.png"),
                text: "TV LCD HD orientabile.",
              },
              
              {
                src: require("../assets/img/img/loghi_stanze_monitor.png"),
                text: "Monitor Touch Screen per il controllo scenari e musiche in Emotica.",
              },
              {
                src: require("../assets/img/img/loghi_stanze_clima.png"),
                text: "Climatizzatore caldo/freddo ad integrazione del comfort personale con controllo a telecomando locale.",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "Asciuga capelli.",
              },
              
              {
                src: require("../assets/img/img/loghi_stanze_idro.png"),
                text: "Minipiscina interna con idromassaggio localizzato per un massimo di 4 persone (informazioni d’uso fornite dal personale al momento del Check In).",
              },
              {
                src: require("../assets/img/img/loghi_stanze_doccia.png"),
                text: "Doccia cromatica",
              },
              

              {
                src: require("../assets/img/img/loghi_stanze_vano.png"),
                text: "Vano passavivande per le vostre richieste extra e l’assoluta privacy.",
              },
              {
                src: require("../assets/img/img/loghi_stanze.png"),
                text: "area esterna riservata con mini-piscina e idromassaggio.",
              },
            ],
            img: [
              {
                src: require("../assets/img/Suite-a-tema-miniera-di-carbone-panoramica.jpg"),
              },
              
              {
                src: require("../assets/img/Suite-a-tema-miniera-di-carbone-panoramica.jpg"),
              },
              
              {
                src: require("../assets/img/Suite-a-tema-miniera-di-carbone-panoramica.jpg"),
              },
              
            ]
          },
        
    
          
          
          
                  
        ]
      }
    },

    mounted(){
      this.tab = this.tabs
    }

  
  }
</script>