<template>
  <v-card
      :class="appbar"
      dark
      style="border-radius: 0 !important;"
  >
    <v-app-bar
      color="transparent"
      
    >

      <v-toolbar-title style="height: 47px">
        <img v-if="main" src="../assets/img/logos.png" style="height: inherit" alt="" to="/">
      </v-toolbar-title>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      style="height: 100vh;"
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class=" text--accent-4"
        >
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item to="/stanze">
            <v-list-item-icon>
              <v-icon>mdi-bed-king</v-icon>
            </v-list-item-icon>
            <v-list-item-title  >
              Stanze
            </v-list-item-title>
          </v-list-item>
          <br>
          <h4 style="text-align:center">Social</h4>
          <v-list-item onclick="window.open('https://api.whatsapp.com/send?phone=393668766245')">
            <v-list-item-icon>
              <v-icon>mdi-whatsapp</v-icon>
            </v-list-item-icon>
            <v-list-item-title  >
              Richiedi informazioni
            </v-list-item-title>
          </v-list-item>
          
          <v-list-item onclick="window.open('https://www.facebook.com/relaxlovetorino')">
            <v-list-item-icon>
              <v-icon>mdi-facebook</v-icon>
            </v-list-item-icon>
            <v-list-item-title  >
              Contattaci su fb
            </v-list-item-title>
          </v-list-item>
          
          <v-list-item  onclick="window.open('https://www.instagram.com/_relaxlove_/')">
            <v-list-item-icon>
              <v-icon>mdi-instagram</v-icon>
            </v-list-item-icon>
            <v-list-item-title  >
              Seguici su ig
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
  export default {
    data: () => ({
      drawer: false,
      group: null,
      appbar: "appbar",
      main: false,
    }),
    watch:  {
       '$route': function(to) {
         if(to.name == "Home"){
          console.log("Home")
          this.appbar = "appbar";
          this.main = false;
        }
        else{
          this.appbar = ""
          this.main = true;
        }
      },

    },


    
    mounted(){
      if(this.$route.name == "Home"){

        this.appbar = "appbar"
         this.main = false;
      }else{ 
         this.main = true;

        this.appbar = ""
      }
    }
  }
</script>