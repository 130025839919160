<template>
  <div >
      <div v-for="(item, i) in obj" :key="i"> 
           <h2>{{obj.tex}}</h2>
            <v-carousel>
                <v-carousel-item
                v-for="(item, i) in item.items"
                :key="i"
                :src="item.src"
                reverse-transition="fade-transition"
                transition="fade-transition"
                ></v-carousel-item>
            </v-carousel>
      </div>
     
  </div>
</template>
<script>
export default {
  data() {
    return {
      obj: [
        {
          tex: "ciao",
          items: [
            {
              src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
            },
            {
              src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
            },
            {
              src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
            },
            {
              src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
            },
          ],
        },
      ],
    };
  },
};
</script>
